import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { filter } from 'rxjs';

@Component({
  selector: 'app-summary-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PageHeaderComponent,
    TabViewModule,
    TranslocoPipe
  ],
  templateUrl: './summary-dashboard.component.html',
  styleUrls: ['./summary-dashboard.component.scss']
})
export class SummaryDashboardComponent implements OnInit {
  tabs = [
    {
      title: 'DASHBOARD.PLANTATIONS',
      route: 'plantations',
      content: 'Plantations',
      index: 0
    },
    {
      title: 'DASHBOARD.LEGALITY',
      route: 'legality',
      content: 'Legality',
      index: 1
    }
  ]

  activeTabIndex = 0;


  constructor(private router: Router, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
     // Listen to route changes
     this.router.events
     .pipe(filter((event) => event instanceof NavigationEnd))
     .subscribe(() => {
       this.syncTabWithRoute();
     });

   // Initial synchronization
   this.syncTabWithRoute();  

  }
  onTabChange(event: TabViewChangeEvent): void {

    let route = null;
    if(event.index === 0) {
      route = 'plantations';
    } else if(event.index === 1) { 
      route = 'legality';
    }

    this.router.navigate([route], {relativeTo: this.activatedRoute});
  }

  private syncTabWithRoute(): void {
    const currentRoute = this.router.url;
    const matchingTabIndex = this.tabs.findIndex((tab) => currentRoute.includes(tab.route));
    this.activeTabIndex = matchingTabIndex > -1 ? matchingTabIndex : 0;
  }
}
