import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  Plantation,
  PlantationTableFilters
} from 'src/app/models/crd-state.interface';

@Injectable({
  providedIn: 'root'
})
export class EventStateService {
  private _zoomPlantation$ = new Subject<Plantation>();
  private _isRiskLoading$ = new BehaviorSubject<boolean>(true);
  private _downloadPlantationInformationLoading$ = new BehaviorSubject<boolean>(
    false
  );
  private _downloadEuIsDataLoading$ = new BehaviorSubject<boolean>(false);
  private _downloadFullRiskReportLoading$ = new BehaviorSubject<boolean>(false);
  private _datasetChanged$ = new Subject<boolean>();
  private _matchColumnsLoading$ = new BehaviorSubject<boolean>(false);
  private _validationLoading$ = new BehaviorSubject<boolean>(false);
  private _isUploadEditInProgress$ = new BehaviorSubject<boolean>(false);
  private _closePlantationUploadModal$ = new BehaviorSubject<boolean>(false);
  private _isImportInProgress$ = new BehaviorSubject<boolean>(false);
  private _hasError$ = new BehaviorSubject<boolean>(false);
  private _isDeletePlantationLoading$ = new BehaviorSubject<boolean>(false);
  private _isBulkDeletePlantationLoading$ = new BehaviorSubject<boolean>(false);
  // plantation and questionnaire table loader
  private _isDashboardTableLoading$ = new BehaviorSubject<boolean>(false);
  private _refreshTable$ = new Subject<boolean>();
  private _plantationFiltersPayload$ =
    new BehaviorSubject<PlantationTableFilters | null>(null);
  private _isTotalSummaryLoading$ = new BehaviorSubject<boolean>(true);
  private _isStatisticsUpdating$ = new BehaviorSubject<boolean>(false);
  private _isPlantationFilterLoading$ = new BehaviorSubject<boolean>(false);
  private _downloadMappingDataQualityReportLoading$ =
    new BehaviorSubject<boolean>(false);
  // used in si linking summary page
  private _isGeometryDataLoading$ = new BehaviorSubject<boolean>(false);
  // used in map page selected plantation details section
  private _isShowMapPlantationDetails$ = new BehaviorSubject<boolean>(false);
  private _hoveredPlantation$ = new Subject<Plantation | null>();

  private _isSettingsValid$ = new BehaviorSubject<boolean>(false);
  private _isSettingsLoading$ = new BehaviorSubject<boolean>(true);
  private _isSystemFeatureLoading$ = new BehaviorSubject<boolean>(true);
  private _bulkSearchValues$ = new Subject<any>();

  private _datasetReportLoadingStates$: {
    [key: string]: BehaviorSubject<boolean>;
  } = {};

  get zoomPlantation$() {
    return this._zoomPlantation$.asObservable();
  }

  set zoomPlantation(plantation: Plantation) {
    this._zoomPlantation$.next(plantation);
  }

  get hoveredPlantation$() {
    return this._hoveredPlantation$.asObservable();
  }

  set hoveredPlantation(hoveredPlantation: Plantation | null) {
    this._hoveredPlantation$.next(hoveredPlantation);
  }

  get plantationFiltersPayload$() {
    return this._plantationFiltersPayload$.asObservable();
  }

  set plantationFiltersPayload(filters: any) {
    this._plantationFiltersPayload$.next(filters);
  }

  get isRiskLoading$() {
    return this._isRiskLoading$.asObservable();
  }

  set isRiskLoading(isRiskLoading: boolean) {
    this._isRiskLoading$.next(isRiskLoading);
  }

  get isShowMapPlantationDetails$() {
    return this._isShowMapPlantationDetails$.asObservable();
  }

  set isShowMapPlantationDetails(isShowMapPlantationDetails: boolean) {
    this._isShowMapPlantationDetails$.next(isShowMapPlantationDetails);
  }

  get isPlantationFilterLoading$() {
    return this._isPlantationFilterLoading$.asObservable();
  }

  set isPlantationFilterLoading(isPlantationFilterLoading: boolean) {
    this._isPlantationFilterLoading$.next(isPlantationFilterLoading);
  }

  get isStatisticsUpdating$() {
    return this._isStatisticsUpdating$.asObservable();
  }

  set isStatisticsUpdating(isStatisticsUpdating: boolean) {
    this._isStatisticsUpdating$.next(isStatisticsUpdating);
  }

  get matchColumnsLoading$() {
    return this._matchColumnsLoading$.asObservable();
  }

  set matchColumnsLoading(matchColumnsLoading: boolean) {
    this._matchColumnsLoading$.next(matchColumnsLoading);
  }

  get validationLoading$() {
    return this._validationLoading$.asObservable();
  }

  set validationLoading(validationLoading: boolean) {
    this._validationLoading$.next(validationLoading);
  }

  get closePlantationUploadModal$(): Observable<boolean> {
    return this._closePlantationUploadModal$.asObservable();
  }

  set closePlantationUploadModal(closePlantationUploadModal: boolean) {
    this._closePlantationUploadModal$.next(closePlantationUploadModal);
  }

  get isImportInProgress$() {
    return this._isImportInProgress$.asObservable();
  }

  set isImportInProgress(isImportInProgress: boolean) {
    this._isImportInProgress$.next(isImportInProgress);
  }

  get isUploadEditInProgress$() {
    return this._isUploadEditInProgress$.asObservable();
  }

  set isUploadEditInProgress(isUploadEditInProgress: boolean) {
    this._isUploadEditInProgress$.next(isUploadEditInProgress);
  }

  get downloadPlantationInformationLoading$() {
    return this._downloadPlantationInformationLoading$.asObservable();
  }

  set downloadPlantationInformationLoading(
    downloadPlantationInformationLoading: boolean
  ) {
    this._downloadPlantationInformationLoading$.next(
      downloadPlantationInformationLoading
    );
  }

  get downloadEuIsDataLoading$() {
    return this._downloadEuIsDataLoading$.asObservable();
  }

  set downloadEuIsDataLoading(downloadEuIsDataLoading: boolean) {
    this._downloadEuIsDataLoading$.next(downloadEuIsDataLoading);
  }

  get downloadFullRiskReportLoading$() {
    return this._downloadFullRiskReportLoading$.asObservable();
  }

  set downloadFullRiskReportLoading(downloadFullRiskReportLoading: boolean) {
    this._downloadFullRiskReportLoading$.next(downloadFullRiskReportLoading);
  }

  get downloadMappingDataQualityReportLoading$() {
    return this._downloadMappingDataQualityReportLoading$.asObservable();
  }

  set downloadMappingDataQualityReportLoading(
    downloadMappingDataQualityReportLoading: boolean
  ) {
    this._downloadMappingDataQualityReportLoading$.next(
      downloadMappingDataQualityReportLoading
    );
  }

  get datasetChanged$() {
    return this._datasetChanged$.asObservable();
  }

  set datasetChanged(datasetChanged: boolean) {
    this._datasetChanged$.next(datasetChanged);
  }

  get hasError$() {
    return this._hasError$.asObservable();
  }

  set hasError(hasError: boolean) {
    this._hasError$.next(hasError);
  }

  get isDeletePlantationLoading$() {
    return this._isDeletePlantationLoading$.asObservable();
  }

  set isDeletePlantationLoading(isDeletePlantationLoading: boolean) {
    this._isDeletePlantationLoading$.next(isDeletePlantationLoading);
  }

  get isBulkDeletePlantationLoading$() {
    return this._isBulkDeletePlantationLoading$.asObservable();
  }

  set isBulkDeletePlantationLoading(isBulkDeletePlantationLoading: boolean) {
    this._isBulkDeletePlantationLoading$.next(isBulkDeletePlantationLoading);
  }

  get isDashboardTableLoading$() {
    return this._isDashboardTableLoading$.asObservable();
  }

  set isDashboardTableLoading(isDashboardTableLoading: boolean) {
    this._isDashboardTableLoading$.next(isDashboardTableLoading);
  }

  get refreshTable$() {
    return this._refreshTable$.asObservable();
  }

  set refreshTable(refreshTable: boolean) {
    this._refreshTable$.next(refreshTable);
  }

  get isTotalSummaryLoading$() {
    return this._isTotalSummaryLoading$.asObservable();
  }

  set isTotalSummaryLoading(isTotalSummaryLoading: boolean) {
    this._isTotalSummaryLoading$.next(isTotalSummaryLoading);
  }

  get isGeometryDataLoading$() {
    return this._isGeometryDataLoading$.asObservable();
  }

  set isGeometryDataLoading(isGeometryDataLoading: boolean) {
    this._isGeometryDataLoading$.next(isGeometryDataLoading);
  }

  get isSettingsValid$() {
    return this._isSettingsValid$.asObservable();
  }

  set isSettingsValid(isSettingsValid: boolean) {
    this._isSettingsValid$.next(isSettingsValid);
  }

  get isSettingsLoading$() {
    return this._isSettingsLoading$.asObservable();
  }

  set isSettingsLoading(isSettingsLoading: boolean) {
    this._isSettingsLoading$.next(isSettingsLoading);
  }

  get isSystemFeatureLoading$() {
    return this._isSystemFeatureLoading$.asObservable();
  }

  set isSystemFeatureLoading(isSystemFeatureLoading: boolean) {
    this._isSystemFeatureLoading$.next(isSystemFeatureLoading);
  }

  set bulkSearchValues(value: any) {
    this._bulkSearchValues$.next(value);
  }

  get bulkSearchValues$() {
    return this._bulkSearchValues$.asObservable();
  }

  // Method to get or create a loading state dynamically
  getDatasetReportLoadingState(id: string): BehaviorSubject<boolean> {
    if (!this._datasetReportLoadingStates$[id]) {
      this._datasetReportLoadingStates$[id] = new BehaviorSubject<boolean>(
        false
      );
    }
    return this._datasetReportLoadingStates$[id];
  }

  // Optionally, if you need to update the loading state
  setDatasetReportLoadingState(id: string, state: boolean) {
    if (this._datasetReportLoadingStates$[id]) {
      this._datasetReportLoadingStates$[id].next(state);
    }
  }

  resetAllLoadingStates() {
    this.isRiskLoading = false;
    this.downloadEuIsDataLoading = false;
    this.downloadPlantationInformationLoading = false;
    this.downloadMappingDataQualityReportLoading = false;
    this.validationLoading = false;
    this.downloadFullRiskReportLoading = false;
    this.isDeletePlantationLoading = false;
    this.isDashboardTableLoading = false;
    this.isTotalSummaryLoading = false;
    this.isStatisticsUpdating = false;
    this.isGeometryDataLoading = false;
    this.isUploadEditInProgress = false;
    this.isImportInProgress = false;
    this.validationLoading = false;
    this.isSettingsLoading = false;
    for (const datasetReportLoader of Object.values(
      this._datasetReportLoadingStates$
    )) {
      datasetReportLoader.next(false);
    }
  }
}
